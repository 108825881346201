import { configureStore } from '@reduxjs/toolkit'
import * as ErrorModule from 'core/modules/error'
import * as Network from 'core/modules/network'
import * as Prescriptions from 'core/modules/prescriptions'
import * as Shops from 'core/modules/shops'
import * as ShopDetail from 'core/modules/shopDetail'
import * as PrescriptionLatest from 'core/modules/prescriptionLatest'
import * as RegisterPrescriptionResult from 'core/modules/registerPrescriptionResult'
import * as PrescriptionPeriod from 'core/modules/prescriptionPeriod'

export const store = configureStore({
  reducer: {
    error: ErrorModule.reducer,
    network: Network.reducer,
    prescriptions: Prescriptions.reducer,
    prescriptionLatest: PrescriptionLatest.reducer,
    registerPrescriptionResult: RegisterPrescriptionResult.reducer,
    shops: Shops.reducer,
    shopDetail: ShopDetail.reducer,
    prescriptionPeriod: PrescriptionPeriod.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
