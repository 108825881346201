import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as FONTS from 'constants/fonts'
import * as COLORS from 'constants/colors'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: 96,
    height: FONTS.TITLE.leading,
    paddingHorizontal: 9,
    borderRadius: 10.5,
    backgroundColor: COLORS.STATUS_NEW,
  },
  statusLabel: {
    fontSize: FONTS.FOOTNOTE.size,
    fontWeight: 'bold',
    color: '#FFF',
    lineHeight: FONTS.TITLE.leading,
    textAlign: 'center',
  },
})

/**
 * Interface
 * @new : 処方せん確認中ステータス
 * @dispensing : 調剤中ステータス
 * @dispensed : 調剤完了ステータス
 * @completed : お渡し済みステータス
 * @warning : 期限間近ステータス
 */
interface StatusTypes {
  [key: string]: StatusType
  new: StatusType
  dispensing: StatusType
  dispensed: StatusType
  completed: StatusType
  warning: StatusType
}

/**
 * Interface
 * @text : ステータスの表示用文字列
 * @backgroundColor : ステータスチップの背景色
 */
interface StatusType {
  text: string
  backgroundColor: string
  color: string
}

/** 処方せんのステータス定義 */
const STATUS_TYPES: StatusTypes = {
  new: {
    text: '確認中',
    backgroundColor: COLORS.STATUS_NEW,
    color: '#FFFFFF',
  },
  dispensing: {
    text: '調剤中',
    backgroundColor: COLORS.STATUS_DISPENSING,
    color: '#FFFFFF',
  },
  dispensed: {
    text: '調剤完了',
    backgroundColor: COLORS.STATUS_DISPENSED,
    color: '#FFFFFF',
  },
  completed: {
    text: 'お渡し済み',
    backgroundColor: COLORS.STATUS_COMPLETED,
    color: '#FFFFFF',
  },
  canceled: {
    text: 'キャンセル',
    backgroundColor: COLORS.STATUS_CANCELED,
    color: '#FFFFFF',
  },
  warning: {
    text: '送信2日経過',
    backgroundColor: COLORS.STATUS_WARNING,
    color: '#FFFFFF',
  },
}

/**
 * Interface
 * @status : ステータスの文字列区分（new/dispensing/dispensed/completed）
 */
interface Props {
  status: string
  isExpireSoon: boolean
}

export default function StatusChip(props: Props) {
  const { status, isExpireSoon } = props

  const currentStatus = useMemo(() => {
    return isExpireSoon ? STATUS_TYPES.warning : STATUS_TYPES[status]
  }, [status, isExpireSoon])

  const currentBackgroundStyles = React.useMemo(() => {
    return StyleSheet.compose(styles.background, {
      ...styles.background,
      backgroundColor: currentStatus.backgroundColor,
    })
  }, [currentStatus])

  const currentLabaelStyles = React.useMemo(() => {
    return StyleSheet.compose(styles.statusLabel, {
      ...styles.statusLabel,
      color: currentStatus.color,
    })
  }, [currentStatus])

  return (
    <View style={currentBackgroundStyles}>
      <Text style={currentLabaelStyles}>{currentStatus.text}</Text>
    </View>
  )
}
