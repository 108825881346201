import React, { useState, useMemo } from 'react'
import { StyleSheet, Image, ScrollView, View } from 'react-native'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { CommonLabel } from 'components/common/atoms/CommonLabel'
import { TelLabel } from 'components/common/atoms/TelLabel'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import { Footer } from 'components/common/atoms/Footer'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import ConfirmModal from 'components/common/ConfirmModal'
import Portal from 'components/common/Portal'
import map from 'assets/images/map.svg'
import fax from 'assets/images/fax.svg'
import windowIcon from 'assets/images/window.svg'
import calendar from 'assets/images/calendar.svg'
import shopIcon from 'assets/images/shop.svg'
import hospital from 'assets/images/hospital.svg'
import noimage from 'assets/images/shop_noimage.svg'
import { ShopInfo } from 'core/domain/shopInfo'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { useNavigateUtility } from 'lib/hooks'

import { liff } from '@line/liff'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  thumbnail: {
    width: '100%',
    height: 202,
    padding: SPACES.MP2,
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: COLORS.BORDER,
  },
  shopImage: {
    width: 240,
    height: 170,
  },
  shopOverview: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
    marginBottom: SPACES.MP2,
  },
  sectionTitle: {
    marginTop: SPACES.MP3,
  },
  shopInfo: {
    marginTop: SPACES.MP2,
  },
  shopOverviewLabel: {
    marginTop: SPACES.MP2,
  },
  shopLinks: {
    marginTop: SPACES.MP3,
  },
  shopLink: {
    height: 40,
  },
  shopLinkOfficial: {
    height: 40,
    marginVertical: SPACES.MP1,
  },
  receptionHours: {
    marginTop: SPACES.MP2,
    paddingLeft: 29,
    paddingRight: SPACES.MP1,
  },
  businessHours: {
    marginTop: SPACES.MP2,
    paddingLeft: 29,
    paddingRight: SPACES.MP1,
  },
})

/**
 * Interface
 * @shop 店舗情報
 */
interface Props {
  shop: ShopInfo | null
}
interface SiteInfo {
  url: string
  external: boolean
}

export default function ShopDetail(props: Props) {
  const { shop } = props
  const [isOpened, setIsOpened] = useState(false)
  const [siteInfo, setSiteInfo] = useState<SiteInfo | null>(null)

  const isShowedMapBtn = useMemo(() => {
    return shop && 'number' === typeof shop.latitude && 'number' === typeof shop.longitude
  }, [shop])

  const isShowedSiteBtn = useMemo(() => {
    return shop && shop.websiteUrl && shop.websiteUrl.length > 0
  }, [shop])

  const { navigate } = useNavigateUtility()

  if (!shop) {
    return <View style={styles.background} />
  }

  const shopDetail = shop

  const openMapPage = (url: string) => {
    // iOS の場合は、InAppBrowser で表示するため、liffの関数を使用
    if (liff.getOS() === 'ios') {
      liff.openWindow({
        url,
        external: false,
      })
      return
    }
    // それ以外は LINE ミニアプリ内で表示するため、href を使用
    location.href = url
  }

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <ScrollView>
        <View style={styles.thumbnail}>
          <Image style={styles.shopImage} source={{ uri: shop.imageUrl ? shop.imageUrl : noimage }} />
        </View>
        <View style={styles.shopOverview}>
          <CommonTitle style={styles.sectionTitle} title={shopDetail.name} />
          {(isShowedMapBtn || isShowedSiteBtn) && (
            <View style={styles.shopLinks}>
              {isShowedMapBtn && (
                <SecondaryButton
                  style={styles.shopLink}
                  label={'Google MAP で 地図を見る'}
                  iconResource={windowIcon}
                  isIconRight={true}
                  disabled={false}
                  onPress={() => {
                    openMapPage(`https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`)
                  }}
                />
              )}
              {isShowedSiteBtn && (
                <SecondaryButton
                  style={styles.shopLinkOfficial}
                  label={'公式サイトで詳細情報を見る'}
                  iconResource={windowIcon}
                  isIconRight={true}
                  disabled={false}
                  onPress={() => {
                    setSiteInfo({
                      url: shop.websiteUrl,
                      external: false,
                    })
                    setIsOpened(true)
                  }}
                />
              )}
            </View>
          )}
          <CommonTitle style={styles.sectionTitle} color={'secondary'} iconResource={hospital} title={'お薬受取時間'} />
          <View style={styles.receptionHours}>
            <CommonLabel title={shopDetail.availableHours} />
            {!!shopDetail.comment && (
              <CommonLabel style={styles.shopOverviewLabel} title={shopDetail.comment} color="secondary" />
            )}
          </View>
          <CommonTitle
            style={styles.sectionTitle}
            color={'secondary'}
            iconResource={shopIcon}
            title={'ドラッグストア営業時間'}
          />
          <View style={styles.businessHours}>
            <CommonLabel title={shopDetail.openingHours} />
          </View>
          <CommonTitle style={styles.sectionTitle} title={'店舗情報'} iconResource={calendar} />
          <View style={styles.shopInfo}>
            <CommonLabel iconResource={map} title={shopDetail.address} />
            <TelLabel style={styles.shopOverviewLabel} phoneNumber={shopDetail.tel} />
            <CommonLabel style={styles.shopOverviewLabel} iconResource={fax} title={shopDetail.fax} />
          </View>
        </View>
      </ScrollView>
      {!shopDetail.isTerminated && (
        <Footer>
          <PrimaryButton
            label="処方せんを送信する"
            // TODO: shop_id を付ける
            onPress={() => navigate('/prescription-send/step-1')}
          />
        </Footer>
      )}
      <Portal targetId={'modal'}>
        <ConfirmModal
          isOpened={isOpened}
          title={'LINEミニアプリの外部に遷移します。'}
          description={'一部の機能が動作しない場合があります。\nよろしいですか？'}
          url={siteInfo ? siteInfo.url : undefined}
          doneLabel={'OK'}
          cancelLabel={'キャンセル'}
          onPressDone={() => {
            if (siteInfo) {
              liff.openWindow({
                url: siteInfo.url,
                external: siteInfo.external,
              })
            }
            setSiteInfo(null)
            setIsOpened(false)
          }}
          onPressCancel={() => {
            setSiteInfo(null)
            setIsOpened(false)
          }}
        />
      </Portal>
    </View>
  )
}
