import { StyleSheet } from 'react-native'
import * as COLORS from './colors'
import * as FONTS from './fonts'

/**
 * 共通の TextInput 用の基本スタイル
 */
const BASE_TEXT_INPUT = {
  height: 48,
  border: `solid 1px ${COLORS.BORDER}`,
  outlineColor: COLORS.SECONDARY,
  justifyContent: 'center' as const,
  borderRadius: 4,
  color: COLORS.TEXT,
  fontSize: FONTS.TITLE.size,
  lineHeight: FONTS.TITLE.leading,
  backgroundColor: '#ffffff',
  padding: 12,
}

/**
 * TextInput 用の style
 */
const TEXT_INPUT = StyleSheet.create({
  BASE: {
    ...BASE_TEXT_INPUT,
  },
  SHORT: {
    ...BASE_TEXT_INPUT,
    width: 240,
  },
  TITLE: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    fontWeight: 'bold',
    color: COLORS.TEXT,
    marginBottom: 4,
  },
  ERROR: {
    border: `solid 1px ${COLORS.ALERT}`,
    backgroundColor: `${COLORS.ALERT}1A`,
  },
  DISABLED: {
    backgroundColor: COLORS.DISABLED,
  },
})

/**
 * セーフエリアパターン
 */
const SAFE_AREA = StyleSheet.create({
  BASE: {
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  },
})

export { TEXT_INPUT, SAFE_AREA }
